// Use Browserify to load jQuery etc!
// Ask in #dev if you don't know how.
window.$ = require('jquery')
window.jQuery = window.$

require('@fancyapps/fancybox')

import './collapsible'
import './filters'
import './login-modal'
import './lightgallery'
import vueApp from './vue-app'
import common from './common'
import jobylonIframeHeight from './jobylon-iframe-height'
import analytics from './analytics'

analytics()
common()
vueApp()
jobylonIframeHeight()

$(document).ready(function() {
	$('[data-fancybox]').fancybox({
		buttons: [
			'close',
			'download', // Include the download button in the toolbar
		],
		btnTpl: {
			download:
				'<a download data-fancybox-download class="fancybox-button fancybox-button--download" title="{{DOWNLOAD}}">' +
				'<span class="fancybox-button__icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.62 17.09V19H5.38v-1.91zm-2.97-6.96L17 11.45l-5 4.87-5-4.87 1.36-1.32 2.68 2.64V5h1.92v7.77z"></path></svg></span>' +
				'<span class="fancybox-button__text">Ladda ner</span>' +
				'</a>',
		},
	})
})
